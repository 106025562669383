import React, { useEffect, useState } from 'react';
import _ from "lodash";

import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Typography, Checkbox, FormGroup, FormControlLabel, FormControl, Button } from '@mui/material';


const CheckboxComponent = ({question, handleNext, handleAnswer, survey_answers}) => {

    const [value, setValue] = useState('')
    const [options, setOptions] = useState([])
    const [disabled, setDisabled] = useState(true)
    const [activeStep, setActiveStep] = useState(false)
    
    useEffect(() => {
        (async () => {
            if(!activeStep) {setActiveStep(true)}
            setOptions(question.type_config.checkbox_options)
            if(survey_answers.length !== 0) {
                let answer = _.find(survey_answers, { 'question_number': question.number });
                if(answer) {
                    setValue(answer.user_response);
                } else {
                    await handleSetValues();
                }
            } else {
                await handleSetValues();
            }

        })()
    }, []);

    const handleSetValues = async () => {
        let options = question.type_config.checkbox_options
        let option_value = {}
         

        for (let i = 0; i < options.length; i++) {
            const option = options[i];
            option_value[option] = false;
        }
        
        setValue(option_value) 
    };
    
    const handleChange = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.checked,
          });
    };

    const submitAnswer = async () => {
        const answer = {
            question: question.question,
            question_number : question.number,
            question_type : question.type,
            user_response: value,
        }
        await handleAnswer(answer)
        handleNext()
    };

    return (
        <Box>
            <FormControl className={activeStep ? 'active' : 'inactive' }>
                <Typography variant="h1" sx={{fontFamily: 'Open Sans', fontWeight: 400, color: '#002846', textAlign: 'left', pb: 4}}>
                <Typography variant="body1" component={'span'} sx={{fontFamily: 'VAG Rounded', color: '#002846', textAlign: 'left'}}>{question.number}. </Typography>
                    {question.question}
                </Typography>
                <FormGroup>
                    {options.map((option, index) => (
                        <FormControlLabel sx={{color: '#002846' }} key={index} control={<Checkbox key={option} checked={value[option] ? true : false} onChange={handleChange} name={option} />} label={option}/>
                    ))}
                </FormGroup>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mt: 2}}>
                    <Button color='primary' size='large' variant='contained' onClick={submitAnswer} sx={{pl: 5, pr: 5, textTransform: 'initial', borderRadius: '10px'}} endIcon={<ArrowRightIcon />}>
                        Submit
                    </Button>
                </Box>
            </FormControl>
        </Box>
    )
}
export default CheckboxComponent
