import React, { useEffect, useState } from 'react';
import {useParams, useLocation} from "react-router";
import _ from "lodash";

import { Container, CircularProgress, Box, Button, MobileStepper } from '@mui/material';
import { RatingComponent, RadioComponent, TextFieldComponent, CheckboxComponent, DoneComponent } from './components';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const Home = () => {

    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const reward_value = query.get('reward')
    const i_customer = query.get('i_customer')

    const {survey_id} = useParams();
    const [activeStep, setActiveStep] = useState(0);
    const [disabledStep, setDisabledStep] = useState(true);
    const [steps, setSteps] = useState(0)
    const [survey, setSurvey] = useState([])
    const [survey_answers, setSurveyAnswers] = useState([])
    const [surveys, setSurveys] = useState([
        {
            id : 1,
            survey: [
                {
                    number: 1,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How would you rate your onboarding experience with us:'
                },
                {
                    number: 2,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'No',
                        number: 2.1,
                        response_question: 'Why did our megstraders not meet your expectations?',
                        type: 'textfield',
                    },
                    question: 'Did our megstraders meet your expectations?'
                },
                {
                    number: 3,
                    type: 'radio',
                    type_config: {
                        radio_options: ['The non-expiry airtime and data', 'The price', 'The megstraders were friendly and helpful', 'The FREE data that I get every day', 'All of the above'],
                    },
                    conditional: false,
                    question: 'What convinced you to buy a megsApp SIM card?'
                },
                {
                    number: 4,
                    type: 'rating',
                    type_config: {
                        format: 'satisfaction',
                        rate_amount: 5,
                    },
                    question: 'Have you been enjoying our App thus far?'
                },
                {
                    number: 5,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How likely would you recommend megsApp to a friend or family member?'
                },
                {
                    number: 6,
                    type: 'radio',
                    type_config: {
                        radio_options: ['No - Everything is great', 'Yes'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Yes',
                        number: 6.1,
                        response_question: 'Tell us what we can improve on:',
                        type: 'textfield',
                    },
                    question: 'Is there anything we could improve on?'
                },
                {
                    number: 7,
                    type: 'done',
                },
            ]
        },
        {
            id : 2,
            survey: [
                {
                    number: 1,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes, every day', 'Sometimes', 'Not yet'],
                    },
                    conditional: false,
                    question: 'Have you been watching ads in-App?'
                },
                {
                    number: 2,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes a lot', "It's okay", 'Not at all'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Not at all',
                        number: 2.1,
                        response_question: 'What about watching ads in-App do you dislike?',
                        type: 'textfield',
                    },
                    question: 'Do you enjoy watching our ads in-App?'
                },
                {
                    number: 3,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Do you think we reward you enough for watching ads in-App?'
                },
                {
                    number: 4,
                    type: 'textfield',
                    conditional: false,
                    question: 'Which brands would you like to see advertising in megsApp?'
                },
                {
                    number: 5,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Bigger rewards', 'More brands that advertise', 'More interactive ads like games'],
                    },
                    conditional: false,
                    question: 'What would make you watch more ads for FREE data?'
                },
                {
                    number: 6,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'No',
                        number: 6.1,
                        response_question: "Why won't you recommend megsApp to a friend?",
                        type: 'textfield',
                    },
                    question: 'Would you recommend megsApp to a friend?'
                },
                {
                    number: 7,
                    type: 'done',
                },
            ]
        },
        {
            id : 3,
            survey: [
                {
                    number: 1,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'Rate your overall experience with megsApp'
                },
                {
                    number: 2,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes - most definitely', "It's okay", 'Not at all'],
                    },
                    conditional: false,
                    question: 'Do you find megsApp easy to use?'
                },
                {
                    number: 3,
                    type: 'radio',
                    type_config: {
                        radio_options: ['The simple to use App', 'The FREE data rewards', 'The top up feature in-App (With bonus 10%)', 'The overall look and feel', 'The service', 'Price', 'Other'],
                    },
                    conditional: false,
                    question: 'What do you like the most about megsApp?'
                },
                {
                    number: 4,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'No',
                        number: 4.1,
                        response_question: 'Why did you find it difficult to top up in-App?',
                        type: 'textfield',
                    },
                    question: 'Did you find it easy to top up your account in-App?'
                },
                {
                    number: 5,
                    type: 'radio',
                    type_config: {
                        radio_options: ['No issues', 'Yes'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Yes',
                        number: 5.1,
                        response_question: 'What issues have you experienced?',
                        type: 'textfield',
                    },
                    question: 'Have you experienced any issues with megsApp?'
                },
                {
                    number: 6,
                    type: 'radio',
                    type_config: {
                        radio_options: ['No - Everything works great!', 'Yes'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Yes',
                        number: 6.1,
                        response_question: 'Tell us what we can improve on:',
                        type: 'textfield',
                    },
                    question: 'Is there anything we could improve on?'
                },
                {
                    number: 7,
                    type: 'done',
                },
            ]
        },
        {
            id : 4,
            survey: [
                {
                    number: 1,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Female', 'Male', 'Other', 'Prefer not to say'],
                    },
                    conditional: false,
                    question: 'What is your gender?'
                },
                {
                    number: 2,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Single', 'Married', 'Seperated or Divorced', 'In a serious relationship', 'Casual dating', 'Widowed'],
                    },
                    conditional: false,
                    question: 'What is your relationship status?'
                },
                {
                    number: 3,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Student', 'Working - Full time', 'Working - Part time', 'Working - Management level or higher', 'Have my own business', 'Stay at home parent', 'Looking for a job'],
                    },
                    conditional: false,
                    question: 'What is your occupation?'
                },
                {
                    number: 4,
                    type: 'radio',
                    type_config: {
                        radio_options: ['0-R3500', 'R3500-R8500', 'R8500-R15000', 'R15000-R25000', 'R25000+'],
                    },
                    conditional: false,
                    question: 'How much do earn per month in Rands?'
                },
                {
                    number: 5,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Do you have your own car?'
                },
                {
                    number: 6,
                    type: 'checkbox',
                    type_config: {
                        checkbox_options: ['Online streaming', "Online betting/gambling (if you're 18+)", 'Online gaming', 'Online shopping' ],
                    },
                    conditional: false,
                    question: 'Which of these online services do you use?'
                },
                {
                    number: 7,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes - defintely', 'Maybe', 'No'],
                    },
                    conditional: false,
                    question: 'Would you buy more of a specific product if we rewarded you with free data for buying it?'
                },
                {
                    number: 8,
                    type: 'textfield',
                    conditional: false,
                    question: 'What are your hobbies?'
                },
                {
                    number: 9,
                    type: 'checkbox',
                    type_config: {
                        checkbox_options: ['Chocolates', 'Peanut Butter', 'Sodas', 'Fast food', 'Fruits', 'Health Supplements', 'Sneakers'],
                    },
                    conditional: false,
                    question: 'Which one of these products do you purchase regularly?'
                },
                {
                    number: 10,
                    type: 'radio',
                    type_config: {
                        radio_options: ['KFC', 'McDonalds', 'Romans Pizza', 'Chicken Licken', 'Nandos', 'Debonairs', 'Steers', 'The Fish & Chip Co' ],
                    },
                    conditional: false,
                    question: 'What is your favourite fast food outlet?'
                },
                {
                    number: 11,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Spar', 'Checkers', 'Woolworths', "Pick 'n Pay", 'Choppies'],
                    },
                    conditional: false,
                    question: 'Where do you buy your groceries?'
                },
                {
                    number: 12,
                    type: 'done',
                },
            ]
        },
        {
            id: 5,
            survey: [
                {
                    number: 1,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Social Media', 'Friend/Family member', 'Website', 'a megstrader approached me'],
                    },
                    conditional: false,
                    question: 'Where did you find out about us?'
                },
                {
                    number: 2,
                    type: 'radio',
                    type_config: {
                        radio_options: ['megsApp', 'MTN', 'Vodacom', 'Cell C', 'FNB Connect', 'Other'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'MTN Vodacom Cell C FNB Connect Other',
                        number: 2.1,
                        response_question: 'What would be required from us to become your primary mobile service provider?',
                        type: 'textfield',
                    },
                    question: 'Who is your primary mobile service provider?'
                },
                {
                    number: 3,
                    type: 'textfield',
                    conditional: false,
                    question: 'Describe what is required from us to become your primary mobile service provider?'
                },
                {
                    number: 4,
                    type: 'done',
                },
            ]
        },
        {
            id: 6,
            survey: [
                {
                    number: 1,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'Please rate the MiSiM App'
                },
                {
                    number: 2,
                    type: 'textfield',
                    conditional: false,
                    question: 'What can we do better?'
                },
                {
                    number: 3,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Have you tried our wallet in the MiSiM App?'
                },
                {
                    number: 4,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Would you buy pre-paid electricity through the MiSiM App?'
                },
                {
                    number: 5,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Did you know that you can Transfer Data to a friend on the MiSiM App?'
                },
                {
                    number: 6,
                    type: 'done',
                }
            ]
        }
    ]);

    useEffect(() => {
        (async () => {
            if(survey_id) {
                await getSurvey(parseInt(survey_id))
            }
        })()
    }, []);

    const getSurvey = async (id) => {
        try {
            let survey = _.find(surveys, { 'id': parseInt(id) });
            setSteps(survey.survey.length +1);
            setSurvey(survey.survey);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if(survey_answers.length !== 0) {
            let answer = _.find(survey_answers, { 'question_number': activeStep+1 });
            if(answer) {
                setDisabledStep(false)
            } else {
                setDisabledStep(true)
            }
        }
    }, [survey_answers, activeStep]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);        
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleAnswer = async (answer) => {
        if(survey_answers.length !== 0) {
            let current_answer = _.find(survey_answers, {'question_number': answer.question_number})
            if(current_answer) { _.remove(survey_answers, survey_answer => survey_answer.question_number === current_answer.question_number)}
        }
        setSurveyAnswers(survey_answers => [...survey_answers, answer])
    };

    return (
        <>
        <Container sx={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}} maxWidth={'xs'}>
            {survey.length === 0 ? 
            <Box>
                <CircularProgress color='primary'/>
            </Box>
            : 
            <Box sx={{width: '100%', mb: 10}}>
                {survey.map((question) => {
                
                if(question.number == activeStep+1) {
                    
                    if (question.type === 'radio') {
                        return <RadioComponent question={question} handleNext={handleNext} handleAnswer={handleAnswer} survey_answers={survey_answers}/>
                    }

                    if (question.type === 'rating') {
                        return <RatingComponent question={question} handleNext={handleNext} handleAnswer={handleAnswer} survey_answers={survey_answers}/>
                    }

                    if (question.type === 'textfield') {
                        return <TextFieldComponent question={question} handleNext={handleNext} handleAnswer={handleAnswer} survey_answers={survey_answers}/>
                    }

                    if (question.type === 'checkbox') {
                        return <CheckboxComponent question={question} handleNext={handleNext} handleAnswer={handleAnswer} survey_answers={survey_answers}/>
                    }

                    if (question.type === 'done') {
                        return <DoneComponent reward_value={reward_value} i_customer={parseInt(i_customer)} survey_answers={survey_answers} survey_id={parseInt(survey_id)} />
                    }
                } 
                    
                })}
            </Box>
            }
            <MobileStepper
                color='primary'
                variant="progress"
                steps={steps-1}
                position="bottom"
                activeStep={activeStep}
                sx={{ maxWidth: 400, flexGrow: 1 }}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === steps || disabledStep}>
                        <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        <KeyboardArrowLeft />
                    </Button>
                }
            />
        </Container>
        </>
    )
}
export default Home
