import React, { useEffect, useState } from 'react';
import _ from "lodash";

import { Typography, TextField, Box, Button } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const TextFieldComponent = ({conditional, question, handleNext, handleAnswer, survey_answers}) => {

    const [value, setValue] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [activeStep, setActiveStep] = useState(false)

    useEffect(() => {
        if(!activeStep) {
            setActiveStep(true)
        }
        if(survey_answers.length !== 0) {
            let answer
            if(conditional) {
                answer = _.find(survey_answers, { 'question_number': question.conditional_config.number });
            } else {
                answer = _.find(survey_answers, { 'question_number': question.number });
            }
            if(answer) {
                setDisabled(false)
                setValue(answer.user_response);
            }
        }
    }, []);

    const handleChange = (event) => {
        if(event.target.length !== 0) {
            setDisabled(false)
        }
      setValue(event.target.value);
    };

    const submitAnswer = async () => {
        const answer = {
            question: question.question,
            question_number : question.number,
            question_type : question.type,
            user_response: value,
        }
        await handleAnswer(answer)
        handleNext()
    };

    const submitConditionalAnswer = async () => {
        const answer = {
            question: question.conditional_config.response_question,
            question_number : question.conditional_config.number,
            question_type : question.conditional_config.type,
            user_response: value,
        }
        await handleAnswer(answer)
        handleNext()
    };

    if(conditional) {
        return (
            <Box className={activeStep ? 'active' : 'inactive'}>
                <Typography variant="h1" sx={{fontFamily: 'Open Sans', fontWeight: 400, color: '#002846', textAlign: 'left', pb: 4}}>
                <Typography variant="body1" component={'span'} sx={{fontFamily: 'VAG Rounded', color: '#002846', textAlign: 'left'}}>{question.conditional_config.number}. </Typography>
                    {question.conditional_config.response_question}
                </Typography>
                <TextField
                    id="outlined-basic" 
                    label="Response" 
                    variant="outlined"     
                    multiline
                    maxRows={4}
                    value={value}
                    onChange={handleChange}
                    fullWidth 
                    sx={{ input: { color: '#002846' }, "& .MuiInputLabel-root": {color: '#002846'}, '& .MuiOutlinedInput-root': { '& fieldset': { border: '2px solid #002846'}, '&:hover fieldset': { border: '2px solid #002846'},'&.Mui-focused fieldset': {border: '2px solid #002846'}}}} />

                    <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mt: 2}}>
                        <Button disabled={disabled} color='primary' size='large' variant='contained' onClick={submitConditionalAnswer} sx={{pl: 5, pr: 5, textTransform: 'initial', borderRadius: '10px'}} endIcon={<ArrowRightIcon />}>
                            Submit
                        </Button>
                    </Box>
            </Box>
        )
    } else {
        return (
            <Box className={activeStep ? 'active' : 'inactive'}>
                <Typography variant="h1" sx={{fontFamily: 'Open Sans', fontWeight: 400, color: '#002846', textAlign: 'left', pb: 4}}>
                <Typography variant="body1" component={'span'} sx={{fontFamily: 'VAG Rounded', color: '#002846', textAlign: 'left'}}>{question.number}. </Typography>
                    {question.question}
                </Typography>
                <TextField
                    id="outlined-basic" 
                    label="Response" 
                    variant="outlined"     
                    multiline
                    maxRows={4}
                    value={value}
                    onChange={handleChange}
                    fullWidth 
                    sx={{ input: { color: '#002846' }, "& .MuiInputLabel-root": {color: '#002846'}, '& .MuiOutlinedInput-root': { '& fieldset': { border: '2px solid #002846'}, '&:hover fieldset': { border: '2px solid #002846'},'&.Mui-focused fieldset': {border: '2px solid #002846'}}}} />

                    <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mt: 2}}>
                        <Button disabled={disabled} color='primary' size='large' variant='contained' onClick={submitAnswer} sx={{pl: 5, pr: 5, textTransform: 'initial', borderRadius: '10px'}} endIcon={<ArrowRightIcon />}>
                            Submit
                        </Button>
                    </Box>
            </Box>
        )
    }

}
export default TextFieldComponent