import React, { useEffect, useState } from 'react';
import _ from "lodash";

import { Typography, Box, CircularProgress } from '@mui/material';
import { createSurvey } from '../../actions'

const DoneComponent = ({ reward_value, i_customer, survey_answers, survey_id }) => {

    const [activeStep, setActiveStep] = useState(false)
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState("")

    useEffect(() => {
        if(!activeStep) { setActiveStep(true)}
    }, []);

    useEffect(() => {
        (async () => {
            if(survey_answers.length !== 0 && i_customer ) {
                let response = await createSurvey(i_customer, survey_id, survey_answers);

                if(response.status_code === 1001) {
                    setMessage(`Thank you, you have been rewarded with ${reward_value}mb`)
                } else {
                    setMessage(`This survey has already been completed.`)
                }
                
                setLoading(false);
            }
        })()
    }, [survey_answers]);

    if(loading) {
        return (
            <Box sx={{textAlign: 'center'}}>
                <CircularProgress color='primary'/>
            </Box>
        )
    } else {
        return (
            <Box className={activeStep ? 'active' : 'inactive'}>
                <Typography variant="body1" sx={{fontFamily: 'Open Sans', fontWeight: 400, color: '#002846', textAlign: 'center', pb: 4}}>
                    {message}
                </Typography>
            </Box>
        )
    }
}
export default DoneComponent