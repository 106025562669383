import React, { useEffect, useState } from 'react';
import _ from "lodash";

import { TextFieldComponent } from '..';
import { Box, Typography, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';


const RadioComponent = ({question, handleNext, handleAnswer, survey_answers}) => {

    const [value, setValue] = useState('')
    const [options, setOptions] = useState([])
    const [activeStep, setActiveStep] = useState(false)
    const [conditional, setConditional] = useState(false)
    const [answer_exists, setAnswerExists] = useState(false)

    useEffect(() => {
        setOptions(question.type_config.radio_options)
        if(!activeStep) {
            setActiveStep(true)
        }
        if(survey_answers.length !== 0) {
            let answer = _.find(survey_answers, { 'question_number': question.number });
            if(answer) {
                setAnswerExists(true)
                setValue(answer.user_response);
            }
        }
    });
    
    useEffect(() => {
        if(value.length !== 0 && !answer_exists) {
            setTimeout(async () => {

                const answer = {
                    question: question.question,
                    question_number : question.number,
                    question_type : question.type,
                    user_response: value,
                }
                await handleAnswer(answer)

                if(question.conditional) {
                    if(value === question.conditional_config.condition || question.conditional_config.condition.includes(value)) {
                        setConditional(true)
                    } else {
                        handleNext()
                    }
                } else {
                    handleNext()
                }
            }, 1000)
        }
    }, [value, answer_exists]);

    const handleChange = (event) => {
      setValue(event.target.value);
      setAnswerExists(false)
    };

    return (
        <Box>
            {!conditional ?
            <FormControl className={activeStep ? 'active' : 'inactive' }>
                <Typography variant="h1" sx={{fontFamily: 'Open Sans', fontWeight: 400, color: '#002846', textAlign: 'left', pb: 4}}>
                <Typography variant="body1" component={'span'} sx={{fontFamily: 'VAG Rounded', color: '#002846', textAlign: 'left'}}>{question.number}. </Typography>
                    {question.question}
                </Typography>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                >
                    {options.map((option, index) => (
                        <FormControlLabel sx={{color: '#002846' }} key={index} value={option} control={<Radio sx={{color: '#002846','&.Mui-checked': {color: '#002846'}}} />} label={option} />
                    ))}
                </RadioGroup>
            </FormControl>
            :
            <Box className={activeStep ? 'active' : 'inactive' }>
                { question.conditional_config.type == 'textfield' && <TextFieldComponent conditional={true} question={question} handleNext={handleNext} handleAnswer={handleAnswer}  survey_answers={survey_answers}/> }
            </Box>
            }
        </Box>
    )
}
export default RadioComponent
