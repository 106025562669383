/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

 import React from 'react'
 import { Route, Switch } from 'react-router-dom'

 import Home from '../views/Home'

 const Routes  = () => {

     return (
         <Switch>
            <Route exact path='/:survey_id'>
              <Home />
            </Route>
         </Switch>
     )
 }

 export {Routes}
