import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";

import { Typography, Box, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const RatingComponent = ({question, handleNext, handleAnswer, survey_answers}) => {

    const [value, setValue] = useState(0);
    const [activeStep, setActiveStep] = useState(false)
    const [answer_exists, setAnswerExists] = useState(false)

    useEffect(() => {
        if(!activeStep) {
            setActiveStep(true)
        }
        if(survey_answers.length !== 0) {
            let answer = _.find(survey_answers, { 'question_number': question.number });
            if(answer) {
                setAnswerExists(true)
                setValue(parseInt(answer.user_response));
            }
        }
    }, []);

    useEffect(() => {
        
        if(value !== 0 && !answer_exists) {
            setTimeout(async () => {
                const answer = {
                    question: question.question,
                    question_number : question.number,
                    question_type : question.type,
                    user_response: value,
                }
                await handleAnswer(answer)
                handleNext()
            }, 1000)
        }
        
    }, [value, answer_exists]);

    const handleChange = (event) => {
        setValue(parseInt(event.target.value));
        setAnswerExists(false)
    };

    const StyledRating = styled(Rating)(({ theme }) => ({
        '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
          color: theme.palette.action.disabled,
        },
    }));
      
    const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon color="error" />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon color="warning" />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon color="success" />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon color="success" />,
        label: 'Very Satisfied',
    },
    };
      
    function IconContainer(props) {
        const { value, ...other } = props;
        return <span {...other}>{customIcons[value].icon}</span>;
    }
    
    IconContainer.propTypes = {
        value: PropTypes.number.isRequired,
    };

    if(question.type_config.format === 'numerical') {
        return (
            <Box className={activeStep ? 'active' : 'inactive' }>
                    <Typography variant="h1" sx={{fontFamily: 'Open Sans', fontWeight: 400, color: '#002846', textAlign: 'left', pb: 4}}>
                    <Typography variant="body1" component={'span'} sx={{fontFamily: 'VAG Rounded', color: '#002846', textAlign: 'left'}}>{question.number}. </Typography>
                        {question.question}
                    </Typography>
                    <Rating
                        className={'numerical_rating'}
                        sx={{color: 'primary.main', borderColor: 'primary.main' }}
                        max={question.type_config.rate_amount}
                        name="numerical-rating"
                        value={value}
                        size="large"
                        onChange={handleChange}
                    />
            </Box>
        )
    } else if (question.type_config.format === 'satisfaction') {
        return (
            <Box className={activeStep ? 'active' : 'inactive' }>
                <Typography variant="h1" sx={{fontFamily: 'Open Sans', fontWeight: 400, color: '#002846', textAlign: 'left', pb: 4}}>
                <Typography variant="body1" component={'span'} sx={{fontFamily: 'VAG Rounded', color: '#002846', textAlign: 'left'}}>{question.number}. </Typography>
                    {question.question}
                </Typography>
                <StyledRating
                    name="satisfaction-rating"
                    value={value}
                    onChange={handleChange}
                    IconContainerComponent={IconContainer}
                    size="large"
                    getLabelText={(value) => customIcons[value].label}
                    highlightSelectedOnly
                />
            </Box>
        )
    }
}
export default RatingComponent